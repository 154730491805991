import React, { FunctionComponent } from 'react'
import LinkButton from './shared/buttons/linkButton'
import Section from './shared/section/sectionWrapper'
//import TitleHeader from './shared/titleHeader'
//import onStopImg from '../images/one-stop-solution.png'
import { StaticImage } from 'gatsby-plugin-image'
import Text from './shared/text'
const OneStopSolution:FunctionComponent = ()=> {
    return (
        <Section className="bg-white p-5 px-6 space-y-5 relative">
            <Text className="text-center text-bytestrone-blue lg:text-right whitespace-nowrap" textType="headerL">Pragmatic One-Stop Solution</Text>
            {/* <StaticImage style={{position:'absolute'}} className="absolute bottom-0 right-0 md:w-1/2" placeholder={'none'} src={"../images/line_green.svg"} alt="background wave"/> */}
            <div className="flex flex-wrap h-full items-center  justify-center md:pl-11 space-y-10">
                
                <div className="lg:hidden">
                  <Text textType="contentXl" className="text-justify">
                     Are you looking for a one-stop quintessential software engineering service? We pride ourselves to be the quintessential engineering partners to breakthrough innovators.
                  </Text>
                </div>
 
                <div className="lg:w-1/2">
                    <StaticImage className="md:w-3/4" style={{maxHeight:"650px"}}  aspectRatio={1236 / 1237}  alt="one-stop solution" placeholder="blurred" quality={100} src="../images/pragmatic-one-stop-solution@2x.png" formats={["auto", "webp", "avif"]}/>
                </div>

                <div className="hidden lg:block lg:w-1/2">
                    <div className="px-8 w-4/5">
                        <Text textType="contentXl">
                            Are you looking for a one-stop quintessential software engineering service? 
                            We pride ourselves to be the quintessential engineering partners to breakthrough innovators.
                        </Text>
                    </div>
                    <div className="flex flex-none px-8 py-8 w-4/5">
                        <LinkButton text="Know More" to="/engineering-excellence" className="md:text-xl font-normal"/>
                    </div>
                </div>
                

                {/* <div className="w-full flex flex-wrap flex-col md:flex-row lg:justify-around items-center space-y-1 lg:space-y-0 text-white">
                    <div className="bg-bytestrone-green rounded-full whitespace-nowrap text-center  p-2 w-64  text-base">PREMIUM QUALITY</div>
                    <div className="bg-bytestrone-green rounded-full whitespace-nowrap text-center  p-2 w-64 text-base">FASTER TIME TO MARKET</div>
                    <div className="bg-bytestrone-green rounded-full whitespace-nowrap text-center  p-2 w-64 text-base">OPTIMAL COQ*</div>
                </div> */}
            </div>

            <div className="flex flex-none w-30 whitespace-nowrap lg:hidden md:justify-end justify-center px-20">
                    <LinkButton text="Know More" to="/engineering-excellence" className="md:text-xl font-normal"/>
            </div>

        </Section>
    )
}


export default OneStopSolution;