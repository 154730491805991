
import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import Hero, { HeroConfig, HeroItems } from "../components/hero"
import ContactUs from "../components/contactUs"
import OneStopSolution from "../components/one-stop-solution"
import HowWeDoIt from "../components/how-we-do-it"
import WhyBytestrone from "../components/why-bytestrone"
import WhatMakesUsUnique from "../components/what-makes-us-unique"

// import Features from "../components/features"
// import CaptionWithBackground from "../components/shared/captionWithBackground"
// import Timeline from "../components/timeline"
// import Testimonials from "../components/testimonials"
// import OurServices from "../components/ourServices"
// import OurLeaders from "../components/ourLeaders"
// import { LottieAnimation } from "../components/shared/lottie"

// import animation from '../animations/31675-programming.json'
// import ContactUs from "../components/contactUs"
// import Articles from "../components/articles"
// import OurTeam from "../components/ourTeam"




const IndexRoute:FunctionComponent<{data:any,pageContext:any}> = ({data})=> {
  const heroConfig:HeroConfig = getHeroConfig(data);
  return (
    <>
      <Hero config={heroConfig} />
      <OneStopSolution/>
      <HowWeDoIt/>
      <WhatMakesUsUnique/>
      <WhyBytestrone/>
      <ContactUs/> 
    </>
  )
}

const getHeroConfig = (data:any):HeroConfig => {
  const heroConfig = data.homeStrapi.edges.map((edge)=>{
    const backgroundImg = edge.node.heroSection.backgroundImage.localFile.childrenImageSharp;
    return {
      style:edge.node.heroSection.isFullSize?"full":"three-forth",
      background:backgroundImg.length>0?backgroundImg[0]:null,
      items:edge.node.heroSection.items.map((item):HeroItems=>{
        return {
          caption:item.caption,
          link:item.link,
          image:item.image.localFile.childImageSharp
        }
      })
    }
  });
  console.log(heroConfig);
  
  return heroConfig.length>0?heroConfig[0]:null;
}

export default  React.memo(IndexRoute);

export const query = graphql`
  query PageQuery($language: String!) {
    homeStrapi:allStrapiHome(filter: {locale: {eq: $language}}) {
      edges {
        node {
          locale
          heroSection {
            id
            isFullSize
            items {
              caption
              link
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width:1920
                      height:1280
                      blurredOptions:{width:100}
                      placeholder:BLURRED
                      transformOptions: {cropFocus:CENTER}
                      formats: [AUTO, WEBP, AVIF]
                      quality: 50,
                      breakpoints: [750, 1080, 1366, 1920],
                    )
                  }
                }
              }
            }
            backgroundImage {
              localFile {
                childrenImageSharp {
                  gatsbyImageData(
                    width:1920
                    height:1280
                    blurredOptions:{width:100}
                    placeholder:BLURRED
                    transformOptions: {cropFocus:CENTER}
                    formats: [AUTO, WEBP, AVIF]
                    quality: 50,
                    breakpoints: [750, 1080, 1366, 1920],
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`;  


    // backgrnd1:file(relativePath: {eq:"blue_splash.png"}) {
    //   childImageSharp {
    //       fluid(maxWidth:1920){
    //         ...GatsbyImageSharpFluid
    //       }
    //   }
    // }
    // backgrnd2:file(relativePath: {eq:"purple_splash.png"}) {
    //   childImageSharp {
    //       fluid(maxWidth:1920){
    //         ...GatsbyImageSharpFluid
    //       }
    //   }
    // },
    // backgrnd3:file(relativePath: {eq:"red_splash.png"}) {
    //   childImageSharp {
    //       fluid(maxWidth:1920){
    //         ...GatsbyImageSharpFluid
    //       }
    //   }
    // }  


