import React, { FunctionComponent } from 'react'
import Section from './shared/section/sectionWrapper'
import { StaticImage } from 'gatsby-plugin-image'
import Text from './shared/text'
import LinkButton from './shared/buttons/linkButton'


const HowWeDoIt:FunctionComponent = ()=> {
    return (
        <Section className="bg-bytestrone-blue p-5 px-6 space-y-5 relative">
            <Text className="text-center text-white lg:text-left whitespace-nowrap" textType="headerL">Precision Crafted Team</Text>
           
            <div className="flex flex-wrap h-full items-center  justify-center md:pl-11">
                
                <div className="lg:w-1/2">
                    <div className="lg:px-8 lg:w-3/4">
                        <Text textType="contentXl" className="text-white">
                            We build precision-crafted, high-performing, ready to deploy engineering team, known as e-pod, that deliver engineering services of unrivaled quality.
                        </Text>
                    </div>
                </div>
 
                <div className="lg:w-1/2 xl:p-10">
                    <StaticImage className="md:w-3/4" style={{maxHeight:"650px"}}  aspectRatio={653 / 708}  alt="precision crafted team" placeholder="blurred" quality={100} src="../images/precision-crafted-team.png" formats={["auto", "webp", "avif"]}/>
                </div>

            </div>

            <div className="flex flex-none w-30 whitespace-nowrap md:justify-end justify-center px-20">
                <LinkButton text="Read More" to="/engineering-excellence#precision-team" className="md:text-xl font-normal"/>
            </div>
        </Section>
    )
}


export default HowWeDoIt;