import React, { FunctionComponent } from 'react'
import Section from './shared/section/sectionWrapper';
import { StaticImage } from 'gatsby-plugin-image'
import Text from './shared/text';
import LinkButton from './shared/buttons/linkButton'

const WhatMakesUsUnique:FunctionComponent = ()=> {
    return (
        <Section className="bg-white px-6 space-y-5 relative">
            {/* <StaticImage style={{position:'absolute'}} className="absolute bottom-0 right-0 md:w-1/2" placeholder={'none'} src={"../images/line_red.svg"} alt="background wave"/> */}
            <Text className="text-center text-bytestrone-blue pt-5 lg:text-right whitespace-nowrap" textType="headerL">What Makes Us Unique?</Text>
            <div className="flex flex-wrap h-full w-full items-start justify-center p-5">
               <div className="h-full md:w-1/3 pb-5">
                    <div className="w-4/5 h-full mx-auto ">
                        <div className="relative flex justify-center items-center p-5">
                            <StaticImage alt="background" style={{position:'absolute'}} src="../images/what-makes-us-unique-bg.png" placeholder="blurred" quality={75} className="absolute left-0 right-0 top-0 bottom-0"/>
                            <StaticImage alt="pod charactristics" src="../images/pod-characteristics.png" placeholder="blurred" quality={75}/>
                        </div>
                        <br/>
                        <Text textType="contentXl" className="text-left">Our pods are built on character, not just skill. The unique characteristics of our pods makes us what we are.</Text>
                    </div>
               </div>
               <div className="h-full md:w-1/3 pb-5">
                    <div className="w-4/5 h-full mx-auto">
                        <div className="relative flex justify-center items-center p-5">
                            <StaticImage alt="background" style={{position:'absolute'}} src="../images/what-makes-us-unique-bg.png" placeholder="blurred" quality={75} className="absolute left-0 right-0 top-0 bottom-0"/>
                            <StaticImage alt="engineering excellence" src="../images/engineering-excellence.png" placeholder="blurred" quality={75}/>
                        </div>
                        <br/>
                        <Text textType="contentXl" className="text-left">Engineering Excellence is second nature to us. We believe Engineering Quality is as important as Functional Quality to deliver value to our customers.</Text>
                    </div>
               </div>
               <div className="h-full md:w-1/3 pb-5">
                    <div className="w-4/5 h-full mx-auto">
                        <div className="relative flex justify-center items-center p-5">
                            <StaticImage alt="background" style={{position:'absolute'}} src="../images/what-makes-us-unique-bg.png" placeholder="blurred" quality={75} className="absolute left-0 right-0 top-0 bottom-0"/>
                            <StaticImage alt="above and beyond" src="../images/above-and-beyond.png" placeholder="blurred" quality={75}/>
                        </div>
                        <br/>
                        <Text textType="contentXl" className="text-left">Customer success is our success. We go above and beyond software delivery to ensure project success.</Text>
                    </div>
               </div>
            </div>

            <div className="flex flex-none w-30 whitespace-nowrap md:justify-end justify-center px-20 pb-5">
                <LinkButton text="Read More" to="/engineering-excellence#unique-characteristics" className="md:text-xl font-normal"/>
            </div>
        </Section>
    )
}

export default WhatMakesUsUnique;