import React, { FunctionComponent } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {Carousel} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import LinkButton from './shared/buttons/linkButton';
import Text from './shared/text';


export interface HeroConfig {
    style:"full" | "three-forth",
    items:HeroItems[],
    background:any
}

export interface HeroItems {
    caption:string,
    link?:string,
    image?:any 
}

//const byteStroneColors = ["bg-bytestrone-blue","bg-bytestrone-green","bg-bytestrone-red","bg-bytestrone-purple"];


const Hero:FunctionComponent<{config:HeroConfig}> = ({config})=> {
    const backgroundImage = config?getImage(config.background):null;
    const carouselItems = config?config.items:[];

    //Method for rendering the carousel
    const renderCarousel = ()=> {
        return (
            <Carousel className="z-20 bg-transparent" dynamicHeight={false} stopOnHover={false} emulateTouch={false} autoPlay={true} interval={6000}  transitionTime={1000} showStatus={false} infiniteLoop={true} swipeable={true} showThumbs={false} showArrows={false} >
                {carouselItems.map((item,index:number)=>{
                    const image = getImage(item.image);
                    const carouselHeight = config.style=="three-forth"?"h-3/4":"h-full";
                    return  (<div  key={`hero_${index}`} className="md:relative z-20 h-screen w-full">
                                    {image?<GatsbyImage objectFit={'cover'} objectPosition={'center'}   image={image} alt="hero images" className={`${carouselHeight}  object-center object-cover w-full`}/>:null}
                                   

                                    <div className={`md:hidden absolute top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center`}>
                                        <h3 className="text-shadow z-20 text-white font-bold uppercase text-center p-5 delay-700">{item.caption}</h3>
                                        {item.link?<LinkButton to={item.link} text="Learn More"/>:null} 
                                    </div> 

                                   

                                <div style={{minWidth:"600px"}} className="hidden absolute z-20 rounded-xl top-0 left-0 bottom-0 right-0 md:flex  flex-col justify-center items-center">     
                                    <div className="flex flex-col items-center justify-center w-3/4">
                                            <Text textType="hero" className="z-20 text-white uppercase text-center p-5 text-shadow">{item.caption}</Text>
                                            {item.link?<LinkButton text="Learn More" to={item.link||"/"} className="md:text-xl font-normal"/>:null}
                                    </div> 
                                </div>
                            </div>)
                })}
            </Carousel>
        );
    };

 
    // Final render method for the component
    return (
        <div className="relative flex justify-center items-center ">
           {backgroundImage?<GatsbyImage alt="hero background" image={backgroundImage} style={{position:'absolute'}} className="absolute z-10 top-0 bottom-0 left-0 right-0 bg-transparent"/>:null}
           {renderCarousel()} 
        </div>)
}


export default Hero;