import React, { FunctionComponent } from "react"
import AniLink from 'gatsby-plugin-transition-link/AniLink'

const LinkButton:FunctionComponent<{to:string,text:string,showBorder?:boolean,className?:string}> = ({to,text,className,showBorder})=> {
    return(
        <AniLink to={to} swipe duration={2} style={{zIndex:'999'}}>
            <div className={`${className} text-white uppercase rounded-full font-base p-2 px-8 bg-bytestrone-red text-sm md:text-lg ${showBorder?"border-solid border-white border-2":""}`}>{text}</div>   
        </AniLink>
    )
}

export default LinkButton;