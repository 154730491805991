//import { StaticImage } from 'gatsby-plugin-image';
import React, { FunctionComponent } from 'react'
import LinkButton from './shared/buttons/linkButton';
import Section from './shared/section/sectionWrapper';
import Text from './shared/text';


const WhyBytestrone:FunctionComponent = ()=> {
    return (
        <Section className="bg-bytestrone-blue pt-5 px-6 relative">
            {/* <StaticImage style={{position:'absolute'}} className="absolute bottom-0 right-0 md:w-1/2" placeholder={'none'} src={"../images/line_green.svg"} alt="background wave"/> */}
            <Text className="text-center text-white lg:text-left whitespace-nowrap" textType="headerL">Why Choose Bytestrone?</Text>
        
            <div className="flex flex-col xl:py-40 h-full space-y-10 p-10">
                <div className="md:w-1/2 md:h-full">
                    <Text className="font-normal text-white" textType="header">Because we hold ourselves to the highest standards of quality and integrity. </Text>
                </div>
                <div className="md:w-1/2 md:h-full flex flex-row md:px-10 md:self-end">
                    <Text className="font-semibold self-end   text-white" textType="contentXl">We focus on teams, not individuals. We succeed as a team where others fail as individuals. We bring projects to the team than setup teams for the project. We go above and beyond to ensure customer success.</Text>
                </div>
            </div>
          
            <div className="flex flex-none w-30 whitespace-nowrap md:justify-end justify-center px-20 pb-5">
                <LinkButton text="Our Services" to="/services" className="md:text-xl font-normal"/>
            </div>            
        </Section>
    )
}


export default WhyBytestrone;